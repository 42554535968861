<template>
  <div class="pre-order-form">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5 d-none">
            <b-dropdown size="sm" right>
              <template slot="button-content">
                <i class="fas fa-cog icon-size"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item>
                <span>
                  <i class="far fa-file-excel icon-size pr-1"></i>
                  Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col cols="4">
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo chương trình"
              v-model="apiParams.formName"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <b-table
              :items="preOrderForms"
              :fields="preOrdersFields"
              :small="true"
              :busy="onLoading"
              responsive
              bordered
              hover
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
              </template>
              <template #cell(statusName)="data">
                <span
                  v-text="data.item.statusName"
                  class="label font-weight-bold label-lg label-inline"
                  :class="
                    data.item.status === 1
                      ? 'label-light-success'
                      : ' label-light-danger'
                  "
                ></span>
              </template>
              <template #cell(actions)="data">
                <b-dropdown size="sm" no-caret right lazy>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-settings pr-0"
                    ></i>
                  </template>
                  <b-dropdown-item @click="editPreOrderForm(data.item)">
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon2-pen"></i>
                      &nbsp; Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-row>
              <b-col>
                <p class="mt-3 text-dark font-weight-bolder">
                  Tổng số :
                  {{ convertPrice(totalItems) }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchData"
                  align="right"
                  first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
                  prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal id="md-update-form" hide-footer title="Cập nhật trạng thái">
      <b-row class="mb-5">
        <b-col>
          <label>Trạng thái</label>
          <b-form-radio-group
            v-model="preOrderForm.status"
            :options="status"
            value-field="id"
            text-field="name"
          ></b-form-radio-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="updatePreOrderForm"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="$bvModal.hide('md-update-form')"
            >Hủy</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import { makeToastFaile, makeToastSuccess, convertPrice } from './../../../utils/common';
import { TIME_OUT } from '../../../utils/constants';
import { cloneDeep } from 'lodash';
export default {
  data() {
    return {
      apiParams: {
        formId: null,
        formName: null,
      },
      title: '',
      preOrderForms: [],
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      preOrdersFields: [
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: {
            width: '18%',
          },
        },
        {
          key: 'name',
          label: 'Tên chương trình',
          thClass: 'text-center',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          tdClass: 'text-center',
          thClass: 'text-center',
          thStyle: {
            width: '18%',
          },
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '8%',
          },
        },
      ],
      onLoading: false,
      preOrderForm: {
        id: null,
        status: null,
      },
      status: [
        { id: 1, name: 'Hoạt động' },
        { id: 0, name: 'Ngừng hoạt động' },
      ],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  computed: {},

  mounted() {
    this.title = 'Danh sách đơn hàng đặt trước';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.fetchData();
  },
  methods: {
    convertPrice,
    editPreOrderForm(form) {
      this.preOrderForm = {
        id: form.id,
        status: form.status,
      };
      this.$bvModal.show('md-update-form');
    },
    updatePreOrderForm: function () {
      const preOrderFormUpdate = cloneDeep(this.preOrderForm);

      return ApiService.put('/pre-orders/form', preOrderFormUpdate)
        .then((response) => {
          if (!response.data.status) {
            makeToastFaile(response.data.message || 'Lỗi');
            return;
          }
          this.onFilter();
          makeToastSuccess(response.data.message);
          this.$bvModal.hide('md-update-form')
        })
        .catch((error) => {
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
          return null;
        });
    },
    getParamFilters() {
      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        formName: this.apiParams.formName
          ? this.apiParams.formName.trim()
          : null,
      };
    },
    onFilter() {
      this.fetchData();
    },
    fetchData: function () {
      if (this.onLoading) return;

      const params = this.getParamFilters();

      this.onLoading = true;
      ApiService.query('pre-orders/form', { params }, { timeout: TIME_OUT })
        .then((response) => {
          const dataset = response.data.data;
          this.totalItems = dataset.total;
          this.numberOfPage = dataset.total_page;
          this.preOrderForms = dataset.data;
          this.onLoading = false;
        })
        .catch((error) => {
          this.onLoading = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi'
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
  },
};
</script>
<style lang="scss">
.pre-order-form {
  .icon-size {
    font-size: 1rem;
  }

  table tbody td {
    vertical-align: middle;
  }
}
</style>
